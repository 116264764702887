import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import { fetchApiHub, useCart } from '@frontastic-engbers/lib';
import { TagManager } from '@frontastic-engbers/lib/lib/tracking';
import { ItemMapper } from '@frontastic-engbers/lib/lib/tracking/itemMapper';
import { UseCart } from '@frontastic-engbers/lib/provider/frontastic/UseCart';
import { updateItemDatalayerPush } from '@frontastic-engbers/helpers/dataLayerHelper/updateItemDatalayerHelper';
import { useFormat } from '@frontastic-engbers/helpers/hooks/useFormat';
import { checkLineItemDuplicates } from '@frontastic-engbers/helpers/utils/checkLineItemDuplicates';
import { getSKUParts } from '@frontastic-engbers/helpers/utils/getSKUParts';
import { EcondaWidgetConfiguration } from '@frontastic-engbers/types/engbers-custom';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Outfit } from '@frontastic-engbers/types/product/Outfit';
import { FlyoutCartSizeSelect } from './flyout-cart-size-select';
import { IconCustom, Image } from '@engbers/components';
import Price from '../../price';
import { AlternativeProductModal } from '../alternative-product-modal';
import styles from './cart-line-items.module.scss';

export interface ILineItems {
  addItem: UseCart['addItem'];
  removeItem: UseCart['removeItem'];
  updateItem: UseCart['updateItem'];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  updateGlobalState: (isFlyoutCartOpen: boolean) => void;
  alternativeProductsConfig?: (EcondaWidgetConfiguration & { sku?: string; categoryUrl?: string }) & Outfit;
  toCheckoutBtnLabel: string;
  toBasketBtnLabel: string;
  headlineAlternativeProduct: string;
  headlineAlternativeOutfit: string;
  continueShoppingLabel: string;
  toOutfitLabel: string;
}

export interface ISizeVariant {
  availableQuantity: number;
  size: string;
  sku: string;
}

export const FlyoutCartLineItems: React.FC<ILineItems> = ({
  addItem,
  removeItem,
  updateItem,
  setLoading,
  updateGlobalState,
  alternativeProductsConfig,
  toCheckoutBtnLabel,
  toBasketBtnLabel,
  headlineAlternativeProduct,
  headlineAlternativeOutfit,
  continueShoppingLabel,
  toOutfitLabel,
}) => {
  const { data: cart } = useCart();
  const [sizeVariants, setSizeVariants] = useState<{ [key: string]: ISizeVariant[] }[]>([]);
  const [removalInProgress, setRemovalInProgress] = useState(false);
  const { formatMessage } = useFormat({ name: 'cart' });
  const lineItems = cart?.lineItems;

  useEffect(() => {
    lineItems?.forEach(async (lineItem) => {
      const skuParts = getSKUParts(lineItem.variant.sku);
      const variants: ISizeVariant[] = await fetchApiHub(`/action/stock/getVariantsStockInfo?key=${skuParts.key}`);

      setSizeVariants((sizeVariants) => [...sizeVariants, { [lineItem.lineItemId]: variants }]);
    });
  }, [lineItems]);

  const handleRemoveItem = async (lineItem: LineItem) => {
    if (removalInProgress) {
      return;
    }

    setRemovalInProgress(true);
    setLoading(true);

    // prevent position split by discount being removed completely when removing the cloned position
    const duplicateCheck = checkLineItemDuplicates(cart, lineItem);

    if (duplicateCheck.occurrences >= 2) {
      await updateItem(lineItem.lineItemId, duplicateCheck.count - lineItem.count);
    } else {
      await removeItem(lineItem.lineItemId);
    }

    const product = await fetchApiHub(`/action/product/getProduct?sku=${lineItem.variant.sku}`);
    const total = Number(
      ((lineItem.totalPrice.centAmount ?? 0) / 10 ** lineItem.price.fractionDigits).toFixed(
        lineItem.price.fractionDigits,
      ),
    );

    new TagManager()
      .removeFromCartEvent([ItemMapper.lineItemToItem(lineItem, lineItem.count)], total)
      .econdaCartEvent(product, lineItem.variant, 'econdaRemoveFromCart', 'c_rmv', lineItem.count)
      .executePush();

    setRemovalInProgress(false);
    setLoading(false);
  };

  const handleUpdateItem = async (lineItem: LineItem, newQuantity: number) => {
    setLoading(true);

    const currentLineItemCount = lineItem.count;
    const product = await fetchApiHub(`/action/product/getProduct?sku=${lineItem.variant.sku}`);
    const currentVariant = lineItem.variant;

    updateItemDatalayerPush(lineItem, newQuantity);
    await updateItem(lineItem.lineItemId, newQuantity);

    new TagManager()
      .econdaCartEvent(
        product,
        currentVariant,
        newQuantity > currentLineItemCount ? 'econdaAddToCart' : 'econdaRemoveFromCart',
        newQuantity > currentLineItemCount ? 'c_add' : 'c_rmv',
        newQuantity > currentLineItemCount ? newQuantity - currentLineItemCount : currentLineItemCount - newQuantity,
      )
      .executePush();

    setLoading(false);
  };

  if (!lineItems) {
    return null;
  }

  const lineItemsSorted = [...lineItems].sort((a, b) => {
    return new Date(b.lastModifiedAt).valueOf() - new Date(a.lastModifiedAt).valueOf();
  });

  return (
    <>
      {lineItemsSorted.map((lineItem, index) => (
        <div key={lineItem.lineItemId + lineItem.cloned} className={styles.lineItemWrap}>
          {alternativeProductsConfig && index === 0 && (
            <AlternativeProductModal
              alternativeProductsConfig={alternativeProductsConfig}
              updateGlobalState={updateGlobalState}
              toCheckoutBtnLabel={toCheckoutBtnLabel}
              toBasketBtnLabel={toBasketBtnLabel}
              headlineAlternativeProduct={headlineAlternativeProduct}
              headlineAlternativeOutfit={headlineAlternativeOutfit}
              continueShoppingLabel={continueShoppingLabel}
              toOutfitLabel={toOutfitLabel}
            />
          )}

          <Link href={lineItem._url}>
            <a onClick={() => updateGlobalState(false)}>
              {lineItem.variant?.images && (
                <Image src={lineItem.variant.images[0]} width={90} alt={lineItem.name} title={lineItem.name} />
              )}
            </a>
          </Link>

          <div className={styles.lineItemInfo}>
            <Link href={lineItem._url}>
              <a onClick={() => updateGlobalState(false)}>
                {lineItem.variant.attributes.DesignerName && <div>{lineItem.variant.attributes.DesignerName}</div>}
                <strong>{lineItem.name}</strong>
              </a>
            </Link>

            {lineItem.discountTexts.map((discountDescription) => (
              <span key={discountDescription} className={styles.lineItemDiscountDescription}>
                {discountDescription}
              </span>
            ))}

            <Price
              price={lineItem.price}
              discountedPrice={lineItem.totalPrice}
              count={lineItem.count}
              className={styles.lineItemInfoPrice}
              isBold
              showCurrencySymbol
            />

            <div className={styles.lineItemInfoActions}>
              <FlyoutCartSizeSelect
                lineItem={lineItem}
                sizeVariants={sizeVariants}
                addItem={addItem}
                removeItem={removeItem}
                updateItem={updateItem}
                setLoading={setLoading}
              />

              {!lineItem.isGift && (
                <div className={styles.lineItemSelectWrap}>
                  <label className={styles.lineItemSelectLabel}>
                    {formatMessage({
                      id: 'quantity',
                      defaultMessage: 'Anzahl',
                    })}
                  </label>
                  <select
                    className={styles.lineItemSelect}
                    name={lineItem.variant.sku}
                    value={
                      (lineItem.count <= lineItem.variant.quantity ? lineItem.count : lineItem.variant.quantity) +
                      (lineItem.hiddenCount || 0)
                    }
                    onChange={(event) => handleUpdateItem(lineItem, Number(event.currentTarget.value))}
                  >
                    {[...Array(lineItem.variant.quantity)].map((_, index) => {
                      const amountLimit = lineItem.count >= 10 ? lineItem.count : 10;
                      const withHiddenCount = index + 1 - (lineItem.hiddenCount || 0);

                      return (
                        index < amountLimit &&
                        withHiddenCount >= 1 && (
                          <option key={`quantity-${index}`} value={index + 1}>
                            {withHiddenCount}
                          </option>
                        )
                      );
                    })}
                  </select>
                </div>
              )}

              <button onClick={() => handleRemoveItem(lineItem)} className={styles.lineItemInfoActionsDelete}>
                <IconCustom icon="TrashBasket" width={20} color="custom" customColor="#566675" />
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
