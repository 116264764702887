import React from 'react';
import classnames from 'classnames';
import { AddressOverview } from './address-overview';
import {
  CheckoutData,
  CheckoutError,
  CheckoutErrorsType,
  NewsletterCheckbox,
} from '@frontastic-engbers/types/engbers-custom';
import { useCart } from '@frontastic-engbers/lib';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { Discount } from '@frontastic-engbers/types/cart/Discount';
import { RefusedGift } from '@frontastic-engbers/types/cart/RefusedGift';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import DiscountForm from '@engbers/components/online-shops/commercetools-ui/discount-form';
import ItemList from '../../../cart/itemList';
import { TotalSummaryOverview } from './total-summary-overview';
import { CtaNewsletterOverview } from './cta-newsletter-overview';
import { SizeDoubledOverview } from './size-doubled-overview';
import styles from './overview.module.scss';
import cartStyles from '../../../cart/cart.module.scss';

interface IOverviewSection {
  checkoutData: CheckoutData;
  overviewSection: {
    headline: string;
    overviewBillingAddressHeadline: string;
    overviewShippingAddressHeadline: string;
    overviewDeliveryTimeHeadline: string;
    overviewPaymentHeadline: string;
    overviewDeliveryTimeDE: string;
    overviewDeliveryTimeEU: string;
    overviewDoubledSizeMessage: string;
  };
  voucherSection: {
    voucherHeadline: string;
    voucherInfo: string;
    voucherRedeemBtn: string;
    voucherInvalidLabel: string;
    voucherRedeemedLabel: string;
    discounts: Discount[];
    setDiscounts: React.Dispatch<React.SetStateAction<Discount[]>>;
  };
  billingAddressSectionRef: HTMLDivElement;
  shippingSectionRef: HTMLDivElement;
  paymentSectionRef: HTMLDivElement;
  overviewSectionRef: HTMLDivElement;
  productOverviewSection: {
    productOverviewArticle: string;
    productOverviewDesc: string;
    productOverviewAmount: string;
    productOverviewSum: string;
  };
  cartFunctions: {
    updateItemQuantity: (lineItem: LineItem, newQuantity: number) => Promise<void>;
    updateItemSize: (variant: Variant, quantity: number, shouldMutate?: boolean) => Promise<void>;
    deleteItem: (lineItem: LineItem) => Promise<void>;
    removeDiscountCode: (discount: Discount) => void;
    setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
    addRefusedGift?: (refusedGift: RefusedGift) => Promise<void>;
  };
  totalSummarySection: {
    commentOnOrderLabel: string;
    shippingCostsNote: string;
    showCommentOnOrderLabel: boolean;
  };
  updateFormInput: (propName: string, newValue: string | NewsletterCheckbox | boolean) => void;
  disableSubmitButton: boolean;
  onSubmit: () => void;
  checkoutErrors: CheckoutErrorsType;
  loggedIn: boolean;
  ctaSection: {
    submitBtnLabel: string;
    enNewsletterLabel: string;
    emilioNewsletterLabel: string;
    xxlNewsletterLabel: string;
    labelAgb: string;
    ctaCheckoutError: CheckoutError;
  };
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatingLineItems: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Overview: React.FC<IOverviewSection> = ({
  checkoutData,
  overviewSection,
  voucherSection,
  billingAddressSectionRef,
  shippingSectionRef,
  paymentSectionRef,
  overviewSectionRef,
  productOverviewSection,
  cartFunctions,
  totalSummarySection,
  updateFormInput,
  disableSubmitButton,
  onSubmit,
  checkoutErrors,
  loggedIn,
  ctaSection,
  setLoading,
  setUpdatingLineItems,
}) => {
  const { data: cart } = useCart();
  const hasOnlyGiftCard = cart?.lineItems?.every((item) => item.variant.attributes.IsGiftcard);

  return (
    <>
      <AddressOverview
        checkoutData={checkoutData}
        overviewBillingAddressHeadline={overviewSection.overviewBillingAddressHeadline}
        overviewShippingAddressHeadline={overviewSection.overviewShippingAddressHeadline}
        overviewDeliveryTimeHeadline={overviewSection.overviewDeliveryTimeHeadline}
        overviewPaymentHeadline={overviewSection.overviewPaymentHeadline}
        overviewDeliveryTimeDE={overviewSection.overviewDeliveryTimeDE}
        overviewDeliveryTimeEU={overviewSection.overviewDeliveryTimeEU}
        billingAddressSectionRef={billingAddressSectionRef}
        shippingSectionRef={shippingSectionRef}
        paymentSectionRef={paymentSectionRef}
        overviewSectionRef={overviewSectionRef}
        hasOnlyGiftCard={hasOnlyGiftCard}
        updateFormInput={updateFormInput}
      />
      <div className={styles.productsOverview}>
        <div className={classnames(cartStyles.orderOverviewHeadlines, styles.productOverviewHeadlines)}>
          <div style={{ width: '20%' }}>{productOverviewSection.productOverviewArticle}</div>
          <div style={{ width: '80%' }} className={cartStyles.productOverviewInfoWrap}>
            <div style={{ width: '60%' }}>{productOverviewSection.productOverviewDesc}</div>
            <div style={{ width: '20%' }}>{productOverviewSection.productOverviewAmount}</div>
            <div style={{ width: '20%' }}>{productOverviewSection.productOverviewSum}</div>
          </div>
        </div>
        <ItemList
          updateItemQuantity={cartFunctions.updateItemQuantity}
          updateItemSize={cartFunctions.updateItemSize}
          deleteItem={cartFunctions.deleteItem}
          setIsUpdating={cartFunctions.setIsUpdating}
          setUpdatingLineItems={setUpdatingLineItems}
          isCheckout
          undoRefuseGift={cartFunctions.addRefusedGift}
          soldOutItems={ctaSection.ctaCheckoutError?.variants ?? []}
          cart={cart}
        />
      </div>
      <div className={styles.overviewVoucherWrap}>
        {cart?.lineItems && (
          <SizeDoubledOverview
            overviewDoubledSizeMessage={overviewSection.overviewDoubledSizeMessage}
            lineItems={cart.lineItems}
          />
        )}
        <DiscountForm
          cart={cart}
          voucherSection={voucherSection}
          setLoading={setLoading}
          setUpdatingLineItems={setUpdatingLineItems}
        />
      </div>
      <TotalSummaryOverview
        commentOnOrderLabel={totalSummarySection.commentOnOrderLabel}
        shippingCostsNote={totalSummarySection.shippingCostsNote}
        showCommentOnOrderLabel={totalSummarySection.showCommentOnOrderLabel}
        updateFormInput={updateFormInput}
        removeDiscountCode={async (discount) => {
          setUpdatingLineItems(true);
          await cartFunctions.removeDiscountCode(discount);
          setUpdatingLineItems(false);
        }}
        checkoutData={checkoutData}
        cart={cart}
      />
      <CtaNewsletterOverview
        ctaSection={ctaSection}
        checkoutData={checkoutData}
        checkoutErrors={checkoutErrors}
        updateFormInput={updateFormInput}
        onSubmit={onSubmit}
        disableSubmitButton={disableSubmitButton}
        loggedIn={loggedIn}
      />
    </>
  );
};
