import React from 'react';
import { VariantSizeSuggestion } from '@frontastic-engbers/helpers/utils/sizeSuggestion';
import { Variant } from '@frontastic-engbers/types/product/Variant';
import { SoldOutVariants } from '@frontastic-engbers/types/engbers-custom';
import Item from './item';
import RefusedGiftItem from './refusedGiftItem';
import { Cart } from '@frontastic-engbers/types/cart/Cart';
import { LineItem } from '@frontastic-engbers/types/cart/LineItem';
import { RefusedGift } from '@frontastic-engbers/types/cart/RefusedGift';

interface Props {
  updateItemQuantity: (lineItem: LineItem, newQuantity: number) => Promise<void>;
  updateItemSize: (variant: Variant, quantity: number, shouldMutate?: boolean) => Promise<void>;
  deleteItem: (lineItem: LineItem) => Promise<void>;
  undoRefuseGift?: (refusedGift: RefusedGift) => Promise<void>
  setIsUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdatingLineItems: React.Dispatch<React.SetStateAction<boolean>>;
  shippingTime?: string;
  isCheckout?: boolean;
  soldOutItems?: SoldOutVariants[];
  cart: Cart;
}

const ItemList = ({
  updateItemQuantity,
  updateItemSize,
  deleteItem,
  undoRefuseGift,
  setIsUpdating,
  setUpdatingLineItems,
  shippingTime,
  isCheckout = false,
  soldOutItems,
  cart,
}: Props) => {
  return (
    <section aria-labelledby="cart-heading">
      <ul role="list">
        {[...(cart?.lineItems || [])]
          .sort((a, b) => new Date(b.lastModifiedAt).valueOf() - new Date(a.lastModifiedAt).valueOf())
          ?.map((lineItem) => (
            <li key={lineItem.lineItemId + lineItem.cloned}>
              <Item
                lineItem={lineItem}
                updateItemQuantity={updateItemQuantity}
                updateItemSize={updateItemSize}
                deleteItem={deleteItem}
                setIsUpdating={setIsUpdating}
                setUpdatingLineItems={setUpdatingLineItems}
                shippingTime={shippingTime}
                isCheckout={isCheckout}
                sizeSuggestion={new VariantSizeSuggestion(lineItem.variant).getSizeSuggestion(cart)}
                soldOutItems={soldOutItems}
              />
            </li>
          ))
        }
        {undoRefuseGift && cart?.refusedGifts?.map((refusedGift) => (
          <li key={`gift-${refusedGift.discount.id}`}>
            <RefusedGiftItem
              refusedGift={refusedGift}
              undoRefuse={undoRefuseGift}
              setUpdatingLineItems={setUpdatingLineItems}
              isCheckout={isCheckout}
            />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default ItemList;
